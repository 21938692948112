body {
  width: 100vw;
  height: 100vh;
  margin: 0;
}

#root {
  width: 100vw;
  height: 100vh;

  background-color: black;
}
